.searchbox {
  .option-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-bottom: 1px solid gray;
    padding: 8px 0;
    &:hover {
      background-color: #eee;
    }
    span {
      color: gray;
      font-size: 12px;
    }
  }
}
