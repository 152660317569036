.chat-box-main {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;

  .chat-list-scroll {
    position: relative;
    overflow-y: scroll;
    width: "100%";
    height: calc(100vh - 200px);
  }
  .action-button {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}
.chat-title {
  text-align: center;
  background-color: white;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  box-shadow: 0 5px 5px #ccc;
}
.chat-section-container {
  height: 100%;
}

.ticket-main-page {
  .ticket-sidebar-container {
    padding-right: 0;
    border-left: 2px solid #3f51b5;
    overflow: hidden;
    position: relative;
  }
  .ticket-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 10px;
  }
}
