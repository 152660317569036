.chat-bubble-container {
  margin-bottom: 20px;
  display: flex;

  &.row-reverse {
    flex-direction: row-reverse;
  }

  .bubble-img {
    align-self: flex-end;
    > img {
      display: inline-block;
      max-width: 100%;
      height: auto;
    }
  }

  .bubble-container {
    padding: 16px;
    max-width: 600px;
    &.admin {
      margin-right: 16px;
      margin-left: 0;
      background-color: lighten($app-primary, 45%);
      border-radius: 20px 20px 0 20px;
      box-shadow: 3px 3px 5px gray;
    }

    &.user {
      background-color: white;
      border-radius: 20px 0 20px 0;
      margin-right: 0;
      margin-left: 16px;
      box-shadow: -3px 3px 5px gray;
    }

    .title {
      color: #792a87;
    }
    .time {
      color: #6c757d;
      margin-top: 10px;
      font-size: 11px;
    }
    .delivery-time {
      margin-top: 10px;
      svg {
        color: $app-primary;
        margin-right: 10px;
      }
    }
  }
}
